<!--
 * @Author: Do not edit
 * @Date: 2021-07-22 14:04:36
 * @LastEditors: zhulin
 * @LastEditTime: 2021-08-13 11:28:12
 * @Description: Do not edit
 * @FilePath: \enfry-website\src\components\casePage.vue
-->
<template>
  <div class="case-section">
      <div class="inner-title wow fadeInUp">全生命周期解决方案</div>
      <el-tabs v-model="active2">
        <el-tab-pane :name="item.id" v-for="item in tabData" :key="item.id">
          <div slot="label" class="tabhead">
            {{item.name}}
          </div>
          <div class="sec-cent">
            <div class="title-head">{{item.name}}</div>
            <div class="cent">
              <div class="txts-lis wow fadeInUp">
                <dl v-for="(item2,ind) in item.list" :key="ind">
                  <dt class="tit">{{item2.name}}</dt>
                  <dd class="txt" v-for="(item3,ind3) in item2.info" :key="ind3">{{item3}}</dd>
                </dl>
              </div>
              <img :src="item.src" :alt="item.name" class=" wow fadeInUp case-bimg"/>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
  </div>
</template>
<script>

export default {
  data() {
    return {
      active2: "3",
      tabData:[
        {
          id:"1",
          name:"客户全生命周期解决方案（CLM）",
          src: require("@/assets/images/practice/page2-sec2-2.png.png"),
          list:[
            {
            name:"01-客户档案",
            info:["基础资料，征信资料，档案","变更，联系人管理，客户组","织架构"]
            },
            {
            name:"02-跟进管理",
            info:["线索/商机分配，公海/","私海管理，异常预警，","打卡管理，日报，周报"]
            },
            {
            name:"03-进度管理",
            info:["任务分配，进度异常预警，","订单进度共享（关键节点客","户查询共享），进度计算"]
            },
            {
            name:"04-报价管理",
            info:["多次报价，邮件与报价关","联，多币种报价，按权限和","层级报价，多部门协同审批"]
            },
            {
            name:"05-签约管理",
            info:["合同范本管理，签约审批，合同","到期提醒，合同变更，在线浏","览，电子签章"]
            },
            {
            name:"06-回款管理",
            info:["回款自动匹配，退款管理，","多次回款匹配，回款异常","提醒"]
            },
            {
            name:"07-发票管理",
            info:["发票申请，总额控制，发票","领用，发票异常处理"]
            },
            {
            name:"08-绩效分配",
            info:["绩效自动计算，绩效自动划","转，绩效分配自定义"]
            }
          ]
        },
        {
          id:"2",
          name:"项目管理全生命周期解决方案（PLM）",
          src: require("@/assets/images/practice/page2-sec2-2.png.png"),
          list:[
            {
            name:"01-项目管理",
            info:["项目状态追踪；","任务分配与报工；","项目任务评价；"]
            },
            {
            name:"02-合同上传",
            info:["关键风控节点，决定后","续的分配和流程"]
            },
            {
            name:"03-合同变更",
            info:["接入门户系统项目信息后，","变更项目费金额，用以开票","总额控制；"]
            },
            {
            name:"04-回款管理",
            info:["导入银行收款流水；","自主认领回款到对应项目"]
            },
            {
            name:"05-开票管理",
            info:["在总额控制内申请开票；","财务收到待办事项进行处理。"]
            },
            {
            name:"06-分配管理",
            info:["可设定多分配组合规则；","根据分配规则自动计算；","分配提取总额控制；"]
            },
            {
            name:"07-项目归档",
            info:["关键分控节点，影响后续绩","效考核；"]
            },
            {
            name:"08-项目借阅",
            info:["可借阅已归档项目的资料"]
            }
          ]
        },
        {
          id:"3",
          name:"合同管理全生命周期解决方案（CoLM）",
          src: require("@/assets/images/practice/page2-sec2-3.png.png"),
          list:[
            {
            name:"01-客户档案",
            info:["基础资料，征信资料，档案","变更，联系人管理，客户组","织架构"]
            },
            {
            name:"02-合同台账",
            info:["合同基础信息，合同执","行情况关联查询"]
            },
            {
            name:"03-签约管理",
            info:["合同范本管理，签约审批，","合同到期提醒，合同变更，","在线浏览，电子签章"]
            },
            {
            name:"04-进度管理",
            info:["合同执行进度关联查询"]
            },
            {
            name:"05-合同控制",
            info:["合同总额控制，合同预算控制"]
            },
            {
            name:"06-合同回款",
            info:["回款自动匹配，退款管理，","多次回款匹配，回款异常","提醒"]
            },
            {
            name:"07-发票管理",
            info:["发票申请，总额控制，发票领","用，发票异常处理"]
            },
            {
            name:"08-合同归档",
            info:["合同正本纸质归档，合同电","子档案归档，合同借阅管理"]
            }
          ]
        },
        {
          id:"4",
          name:"设备管理全生命周期解决方案（ELM）",
          src: require("@/assets/images/practice/page2-sec2-4.png.png"),
          list:[
            {
            name:"01-设备采购申请",
            info:["多对一集约化采购，采购预","算控制，采购申请审批，采","购风险预警"]
            },
            {
            name:"02-设备台账管理",
            info:["设备卡片，设备动态关","联查询"]
            },
            {
            name:"03-设备领用/预约/归还",
            info:["设备使用状态实时更新"]
            },
            {
            name:"04-设备盘点管理",
            info:["二维码设备盘点，物联网设","备盘点"]
            },
            {
            name:"05-设备维修管理",
            info:["设备维修自动提醒；维保期到期","提醒；维修申请；维修登记"]
            },
            {
            name:"06-设备报废管理",
            info:["报废申请，报废过程管控，报废提","前预警"]
            },
            {
            name:"07-设备折旧管理",
            info:["设备折旧参数设置，折旧计","提，财务凭证自动化"]
            },
            {
            name:"08-设备硬软结合",
            info:["设备物理网数据采集，处理","和呈现"]
            }
          ]
        }
      ]
    };
  },
  methods: {
  }
};
</script>
<style lang="scss" scoped>
/deep/ .el-tabs__nav-wrap::after{
  height:0;
}
/deep/ .el-tabs__nav{
  width: 1200px;
  margin:60px auto 30px;
  float: none;
  display:flex;
  flex-wrap: wrap; 
}
/deep/ .el-tabs__active-bar{
  display: none;
}
/deep/ .el-tabs__item{
  width: 560px;
  // width:640px;
  display:block;
  margin-right:30px;
  height:80px;
  box-shadow: 0 0 10px rgba(85, 93, 215, 0.1);
  font-size:22px;
  color:#333;
  border-radius:5px;
  margin-bottom:30px;
  line-height:80px;
  text-align: left;
  padding-left: 30px!important;
  &::before{
    content:"";
    width: 3px;
    background: #0183de;
    height: 28px;
    position: absolute;
    top: 26px;
    left: 15px;
  }
  &:hover{
    color:#0183de
  }
}
/deep/ .el-tabs__item.is-active{
  color:#0183de;
  border:2px solid #0183de;
}
.title-head{
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  line-height: 34px;
  padding-top: 66px;
}
.cent {
  width: 1200px;
  margin: 0 auto;
}
// .case-section{
//   height: 1600px;
// }
  .sec-cent{
  background: url("~@/assets/images/practice/page2-sec2-bg.png") no-repeat;
  }
  .cent {
    margin-top: 70px;
  }
  .txts-lis {
    background: #fff;
    border: 1px solid #ecf3ff;
    box-shadow: 0 0 10px rgba(82, 151, 255, 0.1);
    display: flex;
    flex-wrap: wrap;
    color: #666;
    text-align: left;
    margin: 60px 0 60px;
    dt {
      font-size: 22px;
      color: #0183de;
      font-weight: bold;
      margin-block: 10px;
    }
    dl {
      flex:1;
      // width: 185px;\
      min-width: 200px;
      margin: 38px 0 38px 57px;
    }
    dd {
      margin: 0;
      line-height: 20px;
      font-size: 14px;
    }
  }
@media (max-width: 750px) {
  .cent{
    width:98%;
    margin: 0 auto;
  }
  .sec-cent{
    .cent{
     width:100%;
    }
  }
  /deep/ .el-tabs__nav{
    width:90%;
    margin-top:50px;
    margin-bottom: 10px;
  }
  /deep/ .el-tabs__item{
    width: 100%;
    font-size: 16px;
    height: 42px;
    line-height: 42px;
    margin: 0 auto 14px;
  }
  /deep/ .el-tabs__item::before{
    height:18px;
    top:10px
  }
  .title-head{
    font-size:20px;
    padding-top: 40px;
  }
  .sec-cent{
    background-repeat: repeat;
  }
  .txts-lis{ 
    width: 94%;
    margin: 40px auto;
    dl{
      flex: none;
      width:49%;
      min-width: auto;
      padding-left:10px;
      margin:10px 0 10px 0;
    }
    dt{
      font-size:18px;
    }
    dd{
      font-size:13px;
    }
  }
  .seciton3{
    height: auto;
    padding-bottom:30px;
  }
  // .case-section{
  //   height:1200px;
  // }
  .case-bimg{
    width:100%;
    display:block;
    margin-top:20px
  }
}
</style>
