<!--
 * @Author: Do not edit
 * @Date: 2021-08-06 13:59:02
 * @LastEditors: zhulin
 * @LastEditTime: 2021-08-06 14:01:35
 * @Description: Do not edit
 * @FilePath: \enfry-website\src\components\serviceBanner.vue
-->
<template>
    <div class="banner">
      <img src="@/assets/images/banner-practice-img.png" alt="" class=" wow bounceInRight" />
      <div class="txt-inner wow bounceInLeft">
        <h3>拥抱数据 驱动未来</h3>
        <p>数字化管理应用典范</p>
        <a  href="/Contact">预约演示</a>
      </div>
    </div>
</template>
<script>

export default {
  name: "",
  data() {
    return {
      isComm:true
    };
  },
  mounted(){
  },
  methods: {
  }
};
</script>
<style lang="scss" scoped>
.banner {
  height: 640px;
  position: relative;
  background: url("~@/assets/images/banner-practice.jpg") center top no-repeat;
  img {
    position: absolute;
    right: 50%;
    bottom: 80px;
    margin-right: -600px;
    display: block;
  }
  .txt-inner {
    text-align: left;
    position: absolute;
    left: 50%;
    bottom: 160px;
    margin-left: -600px;
    color:#fff;
    h3 {
      font-size: 48px;
      margin: 0;
      font-weight: normal;
    }
    p {
      font-size: 26px;
    }
    a{
      display: inline-block;
      text-align: center;
      width:330px;
      height:50px;
      line-height: 50px;
      color:#0183de;
      background:#fff;
      font-size:18px;
      border-radius:5px;
      font-size:18px;
      margin-top:30px;
      cursor:pointer;
      text-decoration: none;
    }
  }
}
@media (max-width: 750px) {
  .banner {
    height: 310px;
    overflow: hidden;
    img {
      width: 80%;
      margin-right: -40%;
      bottom: -50px;
      display: none;
    }
    .txt-inner {
      left: auto;
      margin-left: 0;
      top: 108px;
      text-align: center;
      width: 100%;
      h3 {
        font-size: 24px;
        margin: 0;
      }
      p {
        font-size: 24px;
        margin-top:10px;
        margin-bottom: 0;
      }
      a{
        width:160px;
        height: 40px;
        line-height: 40px;
      }
    }
  }
}
</style>
