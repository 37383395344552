<!--
 * @Description: 
 * @Author: 
 * @Date: 2021-03-12 11:01:02
 * @LastEditors: zhulin
 * @LastEditTime: 2021-08-18 14:16:38
-->
<template>
  <div class="service">
    <serviceBanner></serviceBanner>
    <div class="service-one">
      <div class="section2">
        <div class="inner-title  wow fadeInUp">BDA 小微企业数字化</div>
        <div class="cent">
          <ul class="list wow fadeInUp">
            <li v-for="item in professionalLis" :key="item.id" :class="classItem(item.id)">
              <p class="name">{{ item.name }}</p>
              <p class="subtxt">{{ item.subtxt }}</p>
              <span class="icons"></span>
            </li>
          </ul>
        </div>
      </div>
      <div class="section3">
        <div class="inner-title wow fadeInUp">BDA服务优势</div>
        <div class="s-content">
          <div class="list wow fadeInUp">
            <dl v-for="item in professionalLis1" :key="item.id">
              <dt><img :src="item.src" :alt="item.name" /></dt>
              <dd class="col-name">{{ item.name }}</dd>
              <dd class="col-subtxt">{{ item.subtxt }}</dd>
            </dl>
          </div>
        </div>
      </div>
      <div class="section4">
        <div class="inner-title wow fadeInUp">服务生态化</div>
        <div class="s-content">
          <div class="list wow fadeInUp">
            <dl v-for="item in professionalLis2" :key="item.id">
              <dd class="col-name">{{ item.name }}</dd>
              <dd class="col-subtxt">{{ item.subtxt }}</dd>
              <dt><img :src="item.src" :alt="item.name" /></dt>
              <dd class="col-info"><p v-for="(txt, index) in item.list" :key="index">{{txt}}</p></dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
import "swiper/css/swiper.min.css";
import serviceBanner from "../components/serviceBanner.vue"

export default {
  name: "Service",
  components: {
    serviceBanner
  },
  data() {
    return {
      swiperOption: {
        autoplay: true
      },
      professionalLis2: [
        {
          id: 1,
          src: require("@/assets/images/practice/page1-sec3-1.png"),
          name: "数据采集",
          subtxt: "便捷化、去专业化",
          list: [
            "用户只需要做1件事：",
            "1.采集数据（3种方式）",
            "a.OCR识别；",
            "b.手工录入；",
            "c.手工导入；"
          ]
        },
        {
          id: 2,
          src: require("@/assets/images/practice/page1-sec3-2.png"),
          name: "数据处理",
          subtxt: "专业化、封装化、去人化",
          list: [
            "1.发票自动验真和去重；",
            "2.凭证自动生成；",
            "3.三大报表自动生成；",
            "4.税务报表自动生成；",
            "5.合规风险自动预警；"
          ]
        },
        {
          id: 3,
          src: require("@/assets/images/practice/page1-sec3-3.png"),
          name: "数据呈现",
          subtxt: "在线化、多元化、可视化",
          list: [
            "数据多终端可视：",
            "1.业财税数据可视；",
            "2.数据可追溯和穿透；",
            "3.数据多维度分析；"
          ]
        }
      ],
      professionalLis1: [
        {
          id: 1,
          src: require("@/assets/images/practice/page1-sec2-1.png"),
          name: "高效协作",
          subtxt: "审批、流程、费用在线形成闭环"
        },
        {
          id: 2,
          src: require("@/assets/images/practice/page1-sec2-2.png"),
          name: "财务预警",
          subtxt: "账目清晰简明，专业分析及风险预警"
        },
        {
          id: 3,
          src: require("@/assets/images/practice/page1-sec2-3.png"),
          name: "记账报税",
          subtxt: "专业方案化，企业效率显著提升"
        },
        {
          id: 4,
          name: "成本优化",
          src: require("@/assets/images/practice/page1-sec2-4.png"),
          subtxt: "专业处理去人化，省人、省钱、效能提升"
        }
      ],
      professionalLis: [
        {
          id: 1,
          name: "面向群体",
          subtxt: "小微企业"
        },
        {
          id: 2,
          name: "切入方向",
          subtxt: "小微企业记账报税"
        },
        {
          id: 3,
          name: "方案范围",
          subtxt: "覆盖业财税数据处理全生命周期"
        },
        {
          id: 4,
          name: "转化成果",
          subtxt: "已完成5000+客户转化"
        }
      ]
    };
  },
  mounted() {
    this.$nextTick(() => {
      const wow = new WOW({
        live: false
      });
      wow.init();
    });
  },
  computed: {
    // pageTwo() {
    //   return this.$route.query.id;
    // }
  },
  methods: {
    classItem(value) {
      let obj = {numlis: true}
      obj["numlis"+`${value}`] = true
      // console.log(obj);
      return obj
  　}
  }
};
</script>

<style lang="scss" scoped>
.service {
  color: #111;
}
.list{
  display:flex;
  width:1200px;
  margin:60px auto 0;
  padding-bottom:60px;
  justify-content:space-between; 
  dd{
    margin:0;
  }
}
.section2{
  position: relative;
  background:#f1f6ff;
  .cent{
    height:640px;
    position: relative;
    z-index:10;
    background: url("~@/assets/images/practice/page1-sec1.png") center bottom no-repeat;
  }
  &::after{
    content: "";
    height:38px;
    background:#fff;
    position: absolute;
    bottom:0;
    left:0;
    right:0;
  }
  .list{
    position: relative;
  }
  .numlis{
    list-style: none;
    position: absolute;
    border:2px solid #0183de;
    border-radius:5px;
    padding:10px 20px;
    width:315px;
    height:110px;
    background:#f1f6ff;
    &::before{
      position: absolute;
      content:"";
      width: 120px;
      height: 17px;
      top:33px;
    }
    .name{
      font-size:26px;
      margin:0;
      line-height:50px;
      // position: relative;
    }
    .subtxt{
      font-size:18px;
      margin:0;
    }
    .icons{
      position: absolute;
      right: 26px;
      bottom: 18px;
      &::before{
        content: "";
        position:absolute;
        width:0;
        height:0;
        border-width: 20px 28px 20px 0;
        border-style: solid;
        border-color: transparent #0183de transparent transparent;
      }
      &:after{
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        top: -4px;
        left: -2px;
        border-width: 20px 28px 20px 0;
        border-style: solid;
        border-color: transparent #f1f6ff transparent transparent;
      }
    }
  }
  .numlis1{
    text-align: left;
    top:200px;
    left: -45px;
    border:2px solid #fea31f;
    &::before{
      background: rgba(#fea31f,0.3);
    }
    .name{
      color:#fea31f;
    }
    .icons{
      &::before{
        border-color: transparent #fea31f transparent transparent;
      }
    }
  }
  .numlis2{
    text-align: left;
    left:95px;
    border:2px solid #0183de;
    &::before{
      background: rgba(#0183de,0.3);
    }
    .name{
      color:#0183de;
    }
  }
  .numlis3{
    text-align: right;
    right:95px;
    border:2px solid #0ba99d;
    &::before{
      background: rgba(#0ba99d,0.3);
      right:20px;
    }
    .name{
      color:#0ba99d;
    }
    .icons{
      transform: rotateY(180deg);
      left: 26px;
      right: auto;
      &::before{
        border-color: transparent #0ba99d transparent transparent;
      }
    }
  }
  .numlis4{
    text-align: right;
    top:200px;
    right: -75px;
    border:2px solid #ed7d31;
    &::before{
      background: rgba(#ed7d31,0.3);
      right:20px;
    }
    .name{
      color:#ed7d31;
    }
    .icons{
      transform: rotateY(180deg);
      left: 26px;
      right: auto;
      &::before{
        border-color: transparent #ed7d31 transparent transparent;
      }
    }
  }
}
.section3{
  .col-name{
    color:#333;
    font-size:26px;
    margin:20px 0;
  }
  .col-subtxt{
    font-size:14px;
  }
  dt{
    margin-top:20px;
  }
  dl{
    height:360px;
    padding:0;
    border:1px solid #e8ecf2;
    box-shadow: 0 0 8px rgba(85, 93, 215, 0.2);
    cursor: pointer;
    border-radius:5px;
    overflow:hidden;
    &:hover{
      box-shadow: 0 0 8px rgba(1, 131, 222, 0.4);
      border:1px solid #0183de;
      .col-name{
        color: #0183de
      }
    }
  }
}
.section4{
  background:#f1f6ff;
  padding-bottom:60px;
  .col-name{
    font-size: 30px;
    margin-bottom:20px;
  }
  .col-subtxt{
    font-size:18px;
    margin-bottom:20px;
  }
  .col-info{
    font-size:14px;
    line-height:30px;
    margin-top:30px;
    text-align: left;
    padding-left: 100px;
    p{
      margin:0;
    }
  }
}
.inner-title {
  font-weight: bold;
  color: #333333;
  line-height: 36px;
  position: relative;
  z-index: 5;
  h3{
    font-size: 30px;
    font-weight:500;
  }
  p{
    font-size:22px;
    font-weight: normal;
  }
}
.cent {
  width: 1200px;
  margin: 0 auto;
}

@media (max-width: 750px) {
  // .banner {
  //   height: 310px;
  //   overflow: hidden;
  //   img {
  //     width: 80%;
  //     margin-right: -40%;
  //     bottom: -50px;
  //     display: none;
  //   }
  //   .txt-inner {
  //     left: auto;
  //     margin-left: 0;
  //     top: 108px;
  //     text-align: center;
  //     width: 100%;
  //     h3 {
  //       font-size: 24px;
  //       margin: 0;
  //     }
  //     p {
  //       font-size: 24px;
  //       margin-top:10px;
  //       margin-bottom: 0;
  //     }
  //     a{
  //       width:160px;
  //       height: 40px;
  //       line-height: 40px;
  //     }
  //   }
  // }
  .inner-title {
    font-size: 24px;
    line-height: 24px;
    padding-top: 30px;
  }
  .cent {
    width: 98%;
    margin: 0 auto;
  }
  .c-title {
    font-size: 18px;
  }
  .list{
    width:90%;
    flex-wrap: wrap;
  }
  .section2{ 
    .cent{
      height: 440px;
      background-size:100%;
    }
    .list{
      margin:30px auto;
      padding:0;
      display: flex;
      flex-wrap: wrap;
    }
    .numlis3 .icons,.numlis4 .icons{
      transform: inherit;
      right: 26px;
      left: auto;
    }
    .numlis3,.numlis4{
      &::before{
        left:20px;
        right: auto;
      }
    }

    .numlis{
      position: inherit!important;
      top:auto!important;
      left: auto!important;
      right: auto!important;
      width:46%;
      text-align: left!important;
      margin-bottom: 20px;
      &::before{
        width:69px;
        top:36px;
        height:10px;
      }
      .name{
        font-size:16px;
        font-weight: bold;
      }
      .subtxt{
        font-size: 14px;
      }
    }
  }
  .section4{
    .list{
      margin-top: 10px;
    }
    dl{
      position: relative;
      padding-top:240px;
      width: 100%;
    }
    dt{
      position: absolute;
      top:0;
      img{
        width:100%;
      }
    }
    .col-name{
      font-size:18px;
      font-weight: bold;
    }
    .col-subtxt{
      font-size:16px;
    }
    .col-info{
      margin-top:20px;
      font-size:14px;
    // text-align: center;
    // padding-left: 0;
    }
  } 
  .section3{ 
    .list{
      width:94%;
      margin-top:30px
    }
    .col-name{
      font-size: 16px;
      margin: 10px 0;
      font-weight: bold;
    }
    .col-subtxt{
      padding:0 20px;
      line-height:24px;
      color:#999
    }
    dl{
      height: auto;
      width:48%;
      padding-bottom: 20px;
      img{
        width:100%;
      }
    }
  }
}
</style>
