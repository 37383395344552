<!--
 * @Description: 
 * @Author: 
 * @Date: 2021-03-12 11:01:02
 * @LastEditors: zhulin
 * @LastEditTime: 2021-08-18 14:06:35
-->
<template>
  <div class="service-two">
    <serviceBanner></serviceBanner>
    <div class="section2">
      <div class="inner-title  wow fadeInUp">行业解决方案</div>
      <div class="cent">
        <ul class="list wow fadeInUp">
          <li v-for="item in professionalLis" :key="item.id" :class="item.class">
            <img :src="item.src" :alt="item.name" />
          </li>
        </ul>
      </div>
    </div>
    <div class="seciton3">
      <casePage></casePage>
    </div>
    <div class="section4">
      <div class="inner-title wow fadeInUp">IOT物联网解决方案</div>
      <div class="s-content">
        <div class="cent">
        <ul class="list wow fadeInUp">
          <li v-for="item in professionalLis1" :key="item.id" :class="item.class">
            <span class="imgs"></span>
            <p>{{ item.name }}</p>
          </li>
        </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
// import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.min.css";
import casePage from "../components/casePage"
import serviceBanner from "../components/serviceBanner.vue"

export default {
  name: "service-two",
  components: {
    casePage,
    serviceBanner
  },
  data() {
    return {
      swiperOption: {
        autoplay: true
      },
      professionalLis1: [
        {
          id: 1,
          class:"item1",
          src: require("@/assets/images/practice/page2-sec1-1.png"),
          name: "数字化园区"
        },
        {
          id: 2,
          class:"item2",
          src: require("@/assets/images/practice/page2-sec1-2.png"),
          name: "数字化工地"
        },
        {
          id: 3,
          class:"item3",
          src: require("@/assets/images/practice/page2-sec1-3.png"),
          name: "数字化城市"
        },
        {
          id: 4,
          class:"item4",
          src: require("@/assets/images/practice/page2-sec1-4.png"),
          name: "智能印章管理"
        },
        {
          id: 5,
          class:"item5",
          src: require("@/assets/images/practice/page2-sec1-4.png"),
          name: "智能档案管理"
        }
      ],
      professionalLis: [
        {
          id: 1,
          class:"item1",
          src: require("@/assets/images/practice/page2-sec1-1.png"),
          name: "数字化律所"
        },
        {
          id: 2,
          class:"item2",
          src: require("@/assets/images/practice/page2-sec1-2.png"),
          name: "数字化物流"
        },
        {
          id: 3,
          class:"item3",
          src: require("@/assets/images/practice/page2-sec1-3.png"),
          name: "数字化招投标"
        },
        {
          id: 4,
          class:"item4",
          src: require("@/assets/images/practice/page2-sec1-4.png"),
          name: "智能制造"
        }
      ]
    };
  },
  mounted() {
    this.$nextTick(() => {
      const wow = new WOW({
        live: false
      });
      wow.init();
    });
  }
};
</script>

<style lang="scss" scoped>

.section2{
  ul {
    margin: 60px auto 0;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }
  li {
    flex: 1;    
    height:265px;
    border-radius:5px;
    // box-shadow: 0 0 10px rgba(85, 93, 215, 0.1);
    margin: 10px;
    color: #333;
    font-size: 16px;
    font-weight: 600;
    list-style: none;
    img {
      margin-top:46px
    }
  }
  .item1{
    background:#0183de;
  }
  .item2{
    background:#0ba99d;
  }
  .item3{
    background:#ed7d31;
  }
  .item4{
    background:#698aea;
  }
}
.section4{
  ul {
    margin: 60px auto 60px;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }
  li{
    flex: 1;    
    border-radius:5px;
    box-shadow: 0 0 10px rgba(85, 93, 215, 0.1);
    margin: 10px;
    height:304px;
    cursor: pointer;
    list-style: none;
    position: relative;
    border:1px solid #fcfdfd;
    &::before{
      width:117px;
      height:117px;
      content:"";
      display:block;
      background:#f1f1f1;
      position: absolute;
      border-radius:50%;
      top: 56px;
      left: 50%;
      margin-left: -63px;
    }
    p{
      border: 1px solid #0183de;
      width:190px;
      height:48px;
      line-height:48px;
      color:#0183de;
      background:#fff;
      border-radius:5px;
      margin:30px auto 0;
    }
    .imgs{
      background: url("~@/assets/images/practice/page2-sec3.png") no-repeat;
      display:block;
      width:114px;
      height:114px;
      margin:60px auto 0;
      position: relative;
    }
    &.item1{
      .imgs{
        background-position: 0 0;
      }
    }
    &.item2{
      .imgs{
        background-position: -114px 0;
      }
    }
    &.item3{
      .imgs{
        background-position: -230px 0;
      }
    }
    &.item4{
      .imgs{
        background-position: -345px 0;
      }
    }
    &.item5{
      .imgs{
        background-position:-455px 0;
      }
    }
    &:hover{
      border:1px solid #0183de;
      &::before{
        background:#e5f2fc;
      }
      p{
        background:#0183de;
        color:#fff;
      }
      &.item1{
        .imgs{
          background-position: 0 -112px;
        }
      }
      &.item2{
        .imgs{
          background-position: -114px -112px;
        }
      }
      &.item3{
        .imgs{
          background-position: -230px -112px;
        }
      }
      &.item4{
        .imgs{
          background-position: -345px -112px;
        }
      }
      &.item5{
        .imgs{
          background-position:-455px -112px;
        }
      }
    }
  }
}
.seciton3 {
}
.inner-title {
  font-size: 30px;
  font-weight: bold;
  color: #333333;
  line-height: 34px;
  padding-top: 66px;
}
.title-head{
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  line-height: 34px;
  padding-top: 66px;
}
.cent {
  width: 1200px;
  margin: 0 auto;
}
@media (max-width: 750px) {
  .cent{
    width: 98%;
  }
  .inner-title {
    font-size: 24px;
    line-height: 24px;
    padding-top: 30px;
  }
  .section2{ 
    ul{
      margin-top:20px;
      display: flex;
      flex-wrap: wrap;
      li{
        flex: none;
        width: 43%;
        height: auto;
        padding-bottom: 45px;
        img{
          width: 90%;
        }
      }
    }
  }
  .section4{
    li{
      flex: none;
      width:44%;
      height: auto;
      padding-bottom:30px;
      margin-right:0;
      p{
        width:90%;
      }
    }
  } 
}
</style>
